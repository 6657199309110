/** @jsx jsx */

import BackgroundImage from 'gatsby-background-image';
import ComponentFactory from '../components/ComponentFactory';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export const query = graphql`
  query about {
    prismic {
      page(lang: "en-ca", uid: "about") {
        description
        title
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        body {
          ... on PRISMIC_PageBodyText {
            type
            label
            primary {
              text
              repeatable_zone_title
            }
            fields {
              list_item
              list_item_link {
                _linkType
              }
            }
          }
          ... on PRISMIC_PageBodyServices {
            type
            label
            primary {
              feature_description
              feature_layout
              feature_title
            }
          }
        }
      }
    }
  }
`;

export default function Index({
  data: {
    prismic: { page },
  },
}) {
  // console.log("------- data --------->", page);
  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>About Dome GeoConsulting</title>
        <meta name="description" content="About domegeo.ca" />
        <meta
          name="keywords"
          content="hydro-geology, petroleum exploration, mining, structural geology, environmental consulting,project management"
        />
      </Helmet>
      <Layout>
        {page.hero_imageSharp && (
          <BackgroundImage
            className="bgr"
            sx={{ minHeight: [180, 250, 500] }}
            fluid={page.hero_imageSharp.childImageSharp.fluid}
          />
        )}
        <section
          sx={{ width: ['90%', '90%', '80%'], margin: 'auto', mb: [1, 2, 4] }}
        >
          <RichText render={page.title} />
          {page.description && <RichText render={page.description} />}

          {page.body &&
            page.body.map((component, i) => (
              <ComponentFactory
                key={i}
                {...component}
                type={
                  (component.primary && component.primary.feature_layout) ||
                  component.__typename.replace('PRISMIC_', '')
                }
              />
            ))}
        </section>
      </Layout>
    </Fragment>
  );
}
