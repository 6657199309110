import loadable from '@loadable/component';
import React from "react";



export default React.memo(function ComponentFactory({ type, ...props }) {
  const Component = loadable(() => import(`./${type}`), { fallback: null })

  return <Component {...props} />;
});
